<body>
  <div>
    <h3 id="skills" class="section-name">Skills</h3>
  </div>

  <div class="container">
    <div class="card-content">
      <h4 class="brown-text light">Front-End</h4>
      <div class="row text-center">
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/angular.png" class="responsive-img">Angular
        </div>
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/html5.png" class="responsive-img">HTML5
        </div>
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/javascript.png " class="responsive-img">JavaScript
        </div>
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/css3.png" class="responsive-img">CSS
        </div>
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/materialize.png" class="responsive-img">Materialize
        </div>
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/bootstrap.png" class="responsive-img">Bootstrap
        </div>
      </div>
    </div>

    <div class="card-content">
      <h4 class="brown-text light">Back-End</h4>
      <div class="row text-center">
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/nodejs.png" class="responsive-img">NodeJS
        </div>
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/mongodb.png" class="responsive-img">MongoDB
        </div>
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/postgresql.png" class="responsive-img">PostgreSQL
        </div>
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/springboot.jpeg" class="responsive-img">SpringBoot
        </div>
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/postman.png" class="responsive-img">Postman
        </div>
      </div>
    </div>

    <div class="card-content">
      <h4 class="brown-text light">Cloud</h4>
      <div class="row text-center">
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/aws.png" class="responsive-img">Amazon Cloud
        </div>
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/azure.jpeg" class="responsive-img">Azure
        </div>
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/terraform.png" class="responsive-img">Terraform
        </div>
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/git.png" class="responsive-img">Git
        </div>
        <div class="col s6 m2">
          <img alt="" src="../../assets/images/bash.png" class="responsive-img">Bash
        </div>
      </div>
    </div>
  </div>
</body>
