<div>
  <h3 id="projects" class="section-name">Projects</h3>
</div>

<div class="container">
  <div class="row">
    <!-- <div class="col s12 m6">
      <div class="card">
        <div class="card-image waves-effect waves-block waves-light">
          <a
            href="https://github.com/aarizpe10/angular-dev-profile"
            target="_blank"
          >
            <img src="../../assets/images/angular-dev.png" alt="" class="img" />
          </a>
        </div>
        <div class="card-content">
          <span class="card-title activator teal-text hoverline">
            <a
              href="https://github.com/aarizpe10/angular-dev-profile"
              target="_blank"
              >Dev Website - Angular</a
            >`
          </span>
          <p class="description">
            Front End application written with Angular which utilizes HTML, CSS,
            JavaScript, and Materialize framework.
          </p>
        </div>
      </div>
    </div> -->

    <!-- <div class="col s12 m6">
      <div class="card">
        <div class="card-image waves-effect waves-block waves-light">
          <a href="https://github.com/aarizpe10/tf-dev-profile" target="_blank">
            <img
              src="../../assets/images/angular-dev-map.jpg"
              alt=""
              class="img"
            />
          </a>
        </div>
        <div class="card-content">
          <span class="card-title activator teal-text hoverline">
            <a
              href="https://github.com/aarizpe10/tf-dev-profile"
              target="_blank"
              >Dev Website - Cloud</a
            >
          </span>
          <p class="description">
            Terraform (IaC) utilized to create and maintain AWS services such as
            S3, Route 53, CloudFront, and Certificate Manager.
          </p>
        </div>
      </div>
    </div> -->
    <div class="col s12 m6">
      <div class="card">
        <div class="card-image waves-effect waves-block waves-light">
          <a href="https://github.com/aarizpe10/organic" target="_blank">
            <img src="../../assets/images/organics.png" alt="" class="img" />
          </a>
        </div>
        <div class="card-content">
          <span class="card-title activator teal-text hoverline">
            <a href="https://github.com/aarizpe10/organic" target="_blank"
              >Organics Website</a
            >
          </span>
          <p class="description">
            Angular application created to inform visitors of various organic
            gardening information and resources.
          </p>
        </div>
      </div>
    </div>

    <div class="col s12 m6">
      <div class="card">
        <div class="card-image waves-effect waves-block waves-light">
          <a href="https://github.com/Team-OctOS" target="_blank">
            <img src="../../assets/images/octos.png" alt="" class="img" />
          </a>
        </div>
        <div class="card-content">
          <span class="card-title activator teal-text hoverline">
            <a href="https://github.com/Team-OctOS" target="_blank"
              >OctOS ROM - Android</a
            >
          </span>
          <p class="description">
            Assisted in identifying and removing bugs by loading and testing the
            operating system.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>