<div>
  <h3 id="experience" class="section-name">Experience</h3>
</div>

<!-- div with class of container below is 1 card aka 1 job position -->
<div class="container" id="job-card">
  <div class="card">
    <div class="card-content">
      <div class="row">
        <div class="col s12 m2">
          <img src="../../assets/images/ts.png" alt="" class="jobs-icon" />
        </div>
        <div class="col s12 m10">
          <p>
            <span class="card-title">
              <a
                href="https://www.teksystems.com/en/business-transformation?ecid=ps_tek_p_gen__gtmrkt-2021-_google_na_usa_20210921_97a6cebb&vendor_id=4100&gclid=CjwKCAjwl6OiBhA2EiwAuUwWZZcH7kCBVOWf10neIQFuJbSJdHYeiKRyehNhHRc-qpPD09NNojKAzxoC7wUQAvD_BwE"
                target="_blank"
                class="teal-text hoverline"
                >TekSystems</a
              >
            </span>
          </p>
        </div>
        <div class="role brown-text">Platform Software Engineer</div>
      </div>
      <ul>
        <li>
          Automated source code management with CI/CD pipelines, integrating
          Jest unit testing and malware scanning, and automated AWS deployments
          managed by Terraform
        </li>
        <li>
          Improved Lambda performance with layers, cutting cold start times by
          10-20% and execution time by 5-10%, resulting in average cost savings
          of 15-25%
        </li>
        <li>
          Created and enhanced SQS by integrating batch processing performance,
          increasing throughput by 20-30%
        </li>
        <li>
          Developed serverless functionality by utilizing NodeJS to transform
          data, create REST API and implement error handling
        </li>
      </ul>
    </div>
    <div class="card-action">
      <span>March 2022 - March 2024 | San Antonio, TX</span>
    </div>
  </div>
</div>

<div class="container" id="job-card">
  <div class="card">
    <div class="card-content">
      <div class="row">
        <div class="col s12 m2">
          <img src="../../assets/images/ftc.jpeg" alt="" class="jobs-icon" />
        </div>
        <div class="col s12 m10">
          <p>
            <span class="card-title">
              <a
                href="https://ftc-llc.com/"
                target="_blank"
                class="teal-text hoverline"
                >Favor TechConsulting (FTC)</a
              >
            </span>
          </p>
        </div>
        <div class="role brown-text">Java Developer</div>
      </div>
      <ul>
        <li>
          Enhanced Angular applications by implementing new features and
          resolving legacy issues, improving user experiences
        </li>
        <li>
          Developed and maintained REST API services in Spring Boot, utilizing
          Postman and conducting comprehensive end-to-end testing to harden
          back-end services.
        </li>
        <li>
          Gathered client feedback on Angular applications, addressing legacy
          issues and implementing updates
        </li>
      </ul>
    </div>
    <div class="card-action">
      <span>March 2021 - March 2022 | San Antonio, TX</span>
    </div>
  </div>
</div>
<div class="container" id="job-card">
  <div class="card">
    <div class="card-content">
      <div class="row">
        <div class="col s12 m2">
          <img src="../../assets/images/app.jpeg" alt="" class="jobs-icon" />
        </div>
        <div class="col s12 m10">
          <p>
            <span class="card-title">
              <a
                href="https://appddictionstudio.com/"
                target="_blank"
                class="teal-text hoverline"
                >Appddiction Studios</a
              >
            </span>
          </p>
        </div>
        <div class="role brown-text">Entry Level Full Stack Developer</div>
      </div>
      <ul>
        <li>
          Collaborated with Human Resources to implement front end enhancements
          in Angular applications, improving user experience
        </li>
        <li>
          Participated in Agile processes including planning, daily stand ups,
          and retrospectives
        </li>
        <li>
          Developed Angular and Spring Boot web applications for clients,
          enabling form submission
        </li>
      </ul>
    </div>
    <div class="card-action">
      <span>September 2020 - February 2021 | San Antonio, TX</span>
    </div>
  </div>
</div>

<div class="container" id="job-card">
  <div class="card">
    <div class="card-content">
      <div class="row">
        <div class="col s12 m2">
          <img
            src="../../assets/images/td_logo.jpeg"
            alt=""
            class="jobs-icon"
          />
        </div>
        <div class="col s12 m10">
          <p>
            <span class="card-title">
              <a
                href="https://www.techdata.com/vn/en.html"
                target="_blank"
                class="teal-text hoverline"
                >TD Synnex (formerly TechData)</a
              >
            </span>
          </p>
        </div>
        <div class="role brown-text">Associate System Engineer</div>
        <div class="role brown-text">Solution Sales Associate</div>
      </div>
      <ul>
        <li>
          Provided strategic input on architecture, design, and implementation
          for cloud environments across AWS and Azure
        </li>
        <li>
          Designed on premise, hybrid, and cloud architectural plans for data
          storage and analysis
        </li>
        <li>
          Maintained strict SLAs while nurturing client relationships and
          expanding business
        </li>
      </ul>
    </div>
    <div class="card-action">
      <span>July 2017 - February 2020 | San Antonio, TX</span>
    </div>
  </div>
</div>
