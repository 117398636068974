import { Component } from '@angular/core';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';


@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.css'],
    standalone: false
})
export class SideNavComponent {
  faLinkedin = faLinkedin
  faGithub = faGithub
  faDownload = faDownload
  faPhone = faPhone
  faEnvelope = faEnvelope

  // highlightText(event: MouseEvent): void {
  //   const element = event.target as HTMLElement;
  //   if (element) {
  //     element.classList.add('highlighted');
  //   }
  // }

  // removeHighlight(event: MouseEvent): void {
  //   const element = event.target as HTMLElement;
  //   if (element) {
  //     element.classList.remove('highlighted');
  //   }
  // }

    /** 🔹 Fix for MouseEvent & FocusEvent Type Issue */
    highlightText(event: MouseEvent | FocusEvent): void {
      const element = event.target as HTMLElement;
      if (element) {
        element.classList.add('highlighted');
      }
    }
  
    removeHighlight(event: MouseEvent | FocusEvent): void {
      const element = event.target as HTMLElement;
      if (element) {
        element.classList.remove('highlighted');
      }
    }
  
  sites : string[] = [
    'Linkedin', 'Github'
  ]

}
