<div>
  <h3 id="certs" class="section-name">Certifications</h3>
</div>

<div class="container">
  <div class="row">
    <!-- AWS Certified Cloud Practitioner -->
    <div class="col s12 m6 l4 center">
      <div class="card x-small">
        <div class="card-image waves-effect waves-block waves-light">
          <a
            href="https://www.credly.com/badges/c28ed9ee-d3a0-4871-9240-2012d158d51b/public_url"
            target="_blank"
          >
            <img src="../../assets/images/ccp.png" class="img-cert-icon" />
          </a>
        </div>
        <div class="card-content">
          <span class="card-title">
            <a
              href="https://www.credly.com/badges/c28ed9ee-d3a0-4871-9240-2012d158d51b/public_url"
              target="_blank"
              class="teal-text hoverline"
              >AWS Certified Cloud Practitioner</a
            >
          </span>
        </div>
      </div>
    </div>

    <!-- Microsoft Certified: Azure Fundamentals -->
    <div class="col s12 m6 l4 center">
      <div class="card x-small">
        <div class="card-image waves-effect waves-block waves-light">
          <a
            href="https://www.credly.com/badges/b4cbb558-fb5f-481c-ba83-6912ed008d16/public_url"
            target="_blank"
          >
            <img
              src="../../assets/images/azurefundamentals.png"
              class="img-cert-icon"
            />
          </a>
        </div>
        <div class="card-content">
          <span class="card-title">
            <a
              href="https://www.credly.com/badges/b4cbb558-fb5f-481c-ba83-6912ed008d16/public_url"
              target="_blank"
              class="teal-text hoverline"
              >Microsoft Certified: Azure Fundamentals</a
            >
          </span>
        </div>
      </div>
    </div>
    <!-- HashiCorp Certified: Terraform Associate (002) -->
    <div class="col s12 m6 l4 center">
      <div class="card x-small">
        <div class="card-image waves-effect waves-block waves-light">
          <a
            href="https://www.credly.com/badges/660628bf-3f60-4eac-b861-d6fcee9367b2/public_url"
            target="_blank"
          >
            <img src="../../assets/images/tfa.png" class="img-cert-icon" />
          </a>
        </div>
        <div class="card-content">
          <span class="card-title">
            <a
              href="https://www.credly.com/badges/660628bf-3f60-4eac-b861-d6fcee9367b2/public_url"
              target="_blank"
              class="teal-text hoverline"
              >HashiCorp Certified: Terraform Associate</a
            >
          </span>
        </div>
      </div>
    </div>

    <!-- CodeBound Certified Agile Full Stack Software Developer -->
    <div class="col s12 m6 l4 center">
      <div class="card x-small">
        <div class="card-image waves-effect waves-block waves-light">
          <a
            href="https://www.credly.com/badges/3e32a138-2938-435c-acb1-dd0d31caad10/public_url"
            target="_blank"
          >
            <img src="../../assets/images/cb.png" class="img-cert-icon" />
          </a>
        </div>
        <div class="card-content">
          <span class="card-title">
            <a
              href="https://www.credly.com/badges/3e32a138-2938-435c-acb1-dd0d31caad10/public_url"
              target="_blank"
              class="teal-text hoverline"
              >CodeBound Certified Full Stack Developer</a
            >
          </span>
        </div>
      </div>
    </div>

    <!-- Certified ScrumMaster (CSM) -->
    <div class="col s12 m6 l4 center">
      <div class="card x-small">
        <div class="card-image waves-effect waves-block waves-light">
          <a
            href="https://bcert.me/bc/html/show-badge.html?b=kjiyizwp"
            target="_blank"
          >
            <img src="../../assets/images/csm.png" class="img-cert-icon" />
          </a>
        </div>
        <div class="card-content">
          <span class="card-title">
            <a
              href="https://bcert.me/bc/html/show-badge.html?b=kjiyizwp"
              target="_blank"
              class="teal-text hoverline"
              >Certified ScrumMaster (CSM)</a
            >
          </span>
        </div>
      </div>
    </div>

    <!-- ICAgile Certified Professional -->
    <div class="col s12 m6 l4 center">
      <div class="card x-small">
        <div class="card-image waves-effect waves-block waves-light">
          <a
            href="https://www.credly.com/badges/054f9978-9d3b-4cfe-b8e2-53a02c1a780f/public_url"
            target="_blank"
          >
            <img src="../../assets/images/ICP.png" class="img-cert-icon" />
          </a>
        </div>
        <div class="card-content">
          <span class="card-title">
            <a
              href="https://www.credly.com/badges/054f9978-9d3b-4cfe-b8e2-53a02c1a780f/public_url"
              target="_blank"
              class="teal-text hoverline"
              >ICAgile Certified Professional</a
            >
          </span>
        </div>
      </div>
    </div>

    <!-- ICAgile Certified Professional - Agile Programming -->
    <div class="col s12 m6 l4 center">
      <div class="card x-small">
        <div class="card-image waves-effect waves-block waves-light">
          <a
            href="https://www.credly.com/badges/7e7b52ca-d744-412f-9a63-0be320812202/public_url"
            target="_blank"
          >
            <img src="../../assets/images/ICP-PRG.png" class="img-cert-icon" />
          </a>
        </div>
        <div class="card-content">
          <span class="card-title">
            <a
              href="https://www.credly.com/badges/7e7b52ca-d744-412f-9a63-0be320812202/public_url"
              target="_blank"
              class="teal-text hoverline"
              >ICAgile Certified Professional - Agile Programming</a
            >
          </span>
        </div>
      </div>
    </div>

    <!-- ICAgile Certified Professional - Agile Testing -->
    <div class="col s12 m6 l4 center">
      <div class="card x-small">
        <div class="card-image waves-effect waves-block waves-light">
          <a
            href="https://www.credly.com/badges/5da89a62-3158-4cc2-94c9-e738b7857a0c/public_url"
            target="_blank"
          >
            <img src="../../assets/images/ICP-TST.png" class="img-cert-icon" />
          </a>
        </div>
        <div class="card-content">
          <span class="card-title">
            <a
              href="https://www.credly.com/badges/5da89a62-3158-4cc2-94c9-e738b7857a0c/public_url"
              target="_blank"
              class="teal-text hoverline"
              >ICAgile Certified Professional - Agile Testing</a
            >
          </span>
        </div>
      </div>
    </div>
    <!-- End of cards -->
  </div>
</div>
