import { Component, OnInit } from '@angular/core';

import * as M from 'materialize-css';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    const sideNav = document.querySelectorAll('.sidenav');
    M.Sidenav.init(sideNav);
  }
  title = 'angular-dev-profile';
}

