<div>
  <h3 id="about-me" class="section-name">About Me</h3>
</div>

<div class="container flow-text">
  <p class="about">
    Certified Full Stack Developer with over 4 years of experience, including 2
    years specializing in AWS cloud and Terraform development . Skilled in
    automating CI/CD pipelines, cloud-based ETL, and optimizing infrastructure.
    Proficient in Agile workflows, Angular, and Spring Boot, with a focus on
    delivering scalable, high-performance solutions. Adept at delivering
    high-performance, resilient solutions, I am now seeking an opportunity to
    further develop my skills in software development.
  </p>
</div>
