import { Component } from '@angular/core';

@Component({
    selector: 'app-certifications',
    templateUrl: './certifications.component.html',
    styleUrls: ['./certifications.component.css'],
    standalone: false
})
export class CertificationsComponent {

}
