<head>
  <link
    rel="stylesheet"
    href="https://use.fontawesome.com/releases/v5.12.1/css/all.css"
    crossorigin="anonymous"
  />
</head>
<body>
  <div class="sideBar">
    <div class="profile_area">
      <img class="profile_pic" src="../../assets/images/Arizpe.jpeg" />
      <h1 class="firstName">Adrian Arizpe</h1>
      <h2 class="title">Platform Software Engineer</h2>
    </div>
    <div class="sideInfo">
      <li>
        <a href="#about-me" class= "text" (mouseover)="highlightText($event)" (mouseout)="removeHighlight($event)">About Me</a>
      </li>
      <li>
        <a href="#experience" (mouseover)="highlightText($event)" (mouseout)="removeHighlight($event)">Experience</a>
      </li>
      <li>
        <a href="#skills" (mouseover)="highlightText($event)" (mouseout)="removeHighlight($event)">Skills</a>
      </li>
      <li>
        <a href="#projects" (mouseover)="highlightText($event)" (mouseout)="removeHighlight($event)">Projects</a>
      </li>
      <li>
        <a href="#certs" (mouseover)="highlightText($event)" (mouseout)="removeHighlight($event)">Certifications</a>
      </li>
      <li>
        <a href="#education" (mouseover)="highlightText($event)" (mouseout)="removeHighlight($event)">Education</a>
      </li>
      <a
        class="resume_button"
        href="../../assets/AdrianArizpeResume.pdf"
        target="_blank"
        id="resume"
        (mouseover)="highlightText($event)" (mouseout)="removeHighlight($event)"
        >Resume</a
      >
    </div>
    <div class="contactMe">
      <li>Contact Me:</li>
      <a href="https://www.linkedin.com/in/aarizpe10/" target="_blank">
        <fa-icon [icon]="faLinkedin" size="2x"></fa-icon>
      </a>
      <a href="https://github.com/aarizpe10" target="_blank">
        <fa-icon [icon]="faGithub" size="2x"></fa-icon>
      </a>
      <a href="mailto:adrian.arizpe10@gmail.com" target="_blank">
        <fa-icon [icon]="faEnvelope" size="2x"></fa-icon>
      </a>
    </div>
  </div>
</body>
