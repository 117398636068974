<div>
  <h3 id="about-me" class="section-name">About Me</h3>
</div>

<div class="container flow-text">
  <p class="about">
    Certified Full Stack Software Developer with over 6 years of experience in
    the IT space, including nearly 4 years specializing in software based
    solutions and Agile methodologies. At USAA, contributed to database cloud
    migration projects using Terraform and AWS while implementing CICD practices
    to enhance deployment efficiency. In a previous role with the U.S. Air
    Force, developed robust applications using Spring Boot and Angular,
    enhancing critical software in an Agile environment. Adept at delivering
    scalable, high quality software solutions.
  </p>
</div>
