<div>
  <h3 id="education" class="section-name">Education</h3>
</div>

<div class="container">
  <div class="row">
    <div class="col s12 m6">
      <div class="card">
        <div class="card-content">
          <p>
            <span class="card-title">
              <i target="_blank" class="teal-text hoverline">University of Texas at San Antonio</i>
            </span>
          </p>
          <p class="brown-text">
            Bachelor of Science in Computer Science (Fall 2025)
          </p>
        </div>
      </div>
    </div>
    <div class="col s12 m6">
      <div class="card">
        <div class="card-content">
          <p>
            <span class="card-title">
              <i target="_blank" class="teal-text hoverline"
                >Palo Alto College</i
              >
            </span>
          </p>
          <p class="brown-text">
            Associate in Computer Information Systems
          </p>
        </div>
      </div>
    </div>
    <div class="col s12 m6">
      <div class="card">
        <div class="card-content">
          <p>
            <span class="card-title">
              <i target="_blank" class="teal-text hoverline"
                >Palo Alto College</i
              >
            </span>
          </p>
          <p class="brown-text">Associate of Arts in Liberal Arts</p>
        </div>
      </div>
    </div>
  </div>
</div>
