<app-side-nav></app-side-nav>

  <div id="body">
    <app-about-me></app-about-me>
    <app-experience></app-experience>
    <app-skills></app-skills>
    <app-projects></app-projects>
    <app-certifications></app-certifications>
    <app-education></app-education>
  </div>
