import { Component, OnInit } from '@angular/core';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  faLinkedin = faLinkedin
  faGithub = faGithub
  faDownload = faDownload
  faPhone = faPhone
  faEnvelope = faEnvelope

  highlightText(event: MouseEvent): void {
    const element = event.target as HTMLElement;
    if (element) {
      element.classList.add('highlighted');
    }
  }

  removeHighlight(event: MouseEvent): void {
    const element = event.target as HTMLElement;
    if (element) {
      element.classList.remove('highlighted');
    }
  }
  
  constructor() {

   }

  ngOnInit(): void {
  }

  sites : string[] = [
    'Linkedin', 'Github'
  ]

}
